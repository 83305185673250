<template>
  <div class="container-fluid text-left mt-3">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Patient Batches</h4>
      </div>
      <div class="card-body">
        <errors :resp="nc.resp" :retval="nc.retval" class="mt-2"></errors>
        <div class="row mt-2">
          <div class="col-6 text-left">
            <button class="btn btn-outline-secondary" @click="back()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-6 text-right">
            {{ skip }}
            <button class="btn btn-outline-secondary" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>

        <table class="table table-hover table-sm mt-2">
          <thead><tr><th>Patient ID</th><th>Quantity</th><th>Errors</th><th>Created Date</th><th>User</th><th>Study</th><th>Study Code</th><th>Download</th></tr></thead>
          <tbody style="cursor:pointer">
            <tr v-for="item in arr">
              <td>{{ item.participantID}}</td>
              <td>{{ item.addCount}}</td>
              <td>{{ item.errCount}}</td>
              <td>{{ item.createdDate}}</td>
              <td>{{ item.user}}</td>
              <td>{{ item.studyFlag ? 'YES' : 'NO'}}</td>
              <td>{{ item.studyCode}}</td>
              <td><a target="_blank" :href="host+'rest/patient?uid='+encodeURI(nc.ctx.uid)+'&token='+nc.ctx.token+'&oid='+item.oid"><i class="fa fa-file-excel-o"></i></a></td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  mounted () {
      this.search()
  },
  data () {
    return {
      nc: NetClient,
      host: process.env.VUE_APP_HOST_NAME,
      participantID: '',
      oldPid: '',
      skip: 0,
      limit: 10,
      nc: NetClient,
      arr: []
    }
  },
  methods: {
    search () {
      NetClient.doRequest('rest/patient', { action: 'searchPatientBatch', skip: this.skip, limit: this.limit }).then(msg => {
        if(typeof msg.data != 'undefined') this.arr = msg.data
        else this.arr = []
      })
    },
    sessionone () {
      this.$router.push('/participant/sessions')
    },
    next () {
      if(this.arr.length >= this.limit)
      {
        this.skip = this.skip + this.limit
        this.search()
      }

    },
    back () {
      this.skip = this.skip - this.limit
      if(this.skip < 0) this.skip = 0
      this.search()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
